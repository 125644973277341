import { NEXTJS_REVALIDATE_PAGE_IN_SECONDS } from 'pages/index';
import { GenericPageLayoutProps } from 'components/general/genericPageLayout/GenericPageLayout';
import { parseGenericPage } from 'utils/parsers';
import style from './index404.module.scss';
import { CustomMetaDataProps } from 'components/general/customMetaData/CustomMetaData';
import { useGlobalContentContext } from 'context/GlobalContentContext';
import { useEffect, useRef, useState } from 'react';
import SVG404 from './404.svg';
import { Logo } from 'components/logo/Logo';
import { useRouter } from 'next/router';
import gsap from 'gsap/dist/gsap';
import { MainFooter } from 'components/mainFooter/MainFooter';
import Link from 'next/link';

type Props = {
  generic: GenericPageLayoutProps;
  metaData: CustomMetaDataProps;
};

const PageNotFound = (props: Props) => {
  const { setData } = useGlobalContentContext();

  const router = useRouter();

  const onClick = () => {
    router.push('/');
  };
  useEffect(() => {
    setData(props.generic.globalData);
  }, []);

  return (
    <div className="js-404-container">
      <div className={`pageNotFound__container`}>
        <h1 className={`pageNotFound__title`}>
          <SVG404 />
        </h1>
        <div className={`pageNotFound__footer`}>
          <MainFooter showMenu={true} showSocial={true} />
        </div>
      </div>
    </div>
  );
};

export async function getStaticProps(context) {
  const genericProps: GenericPageLayoutProps = await parseGenericPage('/careers-page/', context);

  // Pass data to the page via props

  const props: Props = {
    generic: genericProps,
    metaData: {
      // title: genericProps.originalResponse.meta_title,
      // description: genericProps.originalResponse.meta_description,
      // image: genericProps.originalResponse.meta_image,
    },
  };
  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export default PageNotFound;
